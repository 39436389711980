import { mapActions, mapState } from 'vuex';
import { $EventBus } from '@/main';
import SupplierSearch from '@/components/agreement/elements/SupplierSearch/SupplierSearch.vue';
import ContractForm from '@/components/agreement/elements/ContractForm/ContractForm.vue';
import NotificationUsersDialog from '@/components/agreement/elements/NotificationUsersDialog/NotificationUsersDialog.vue';

export default {
	name: 'CreateContract',
	props: {},
	data: () => ({
		loading: false,
		form: {
			documentType: null,
			owner: null,
		},
		valid: false,
		requiredRule: [(v) => !!v || 'Campo requerido'],

		fields: [],
		openNotificationUsersDialog: false,
	}),
	computed: {
		...mapState('agreement', ['documentTypes', 'template']),
		breadcrumbs: function () {
			return [
				{
					text: 'Inicio',
					link: true,
					exact: true,
					disabled: false,
					to: {
						name: 'Dashboard',
					},
				},
				{
					text: 'Abastecimiento',
					link: true,
					exact: true,
					disable: false,
					to: {
						name: 'AgreementHome',
					},
				},
				{
					text: 'Documentos',
					disabled: true,
					href: '/',
				},
			];
		},
		info: function () {
			return {
				agreement_type_id: this.form.documentType?.id,
				owner_id: this.form.owner?.id,
			};
		},
	},
	watch: {
		// currentFile: {
		// 	handler() {
		// 		this.setIndicators();
		// 	},
		// 	deep: true,
		// },
	},
	created() {
		this.listDocumentType();
	},
	methods: {
		...mapActions('agreement', [
			'listDocumentType',
			'getTemplate',
			'cleanDocumentType',
			'cleanTemplate',
		]),
		async getFormat() {
			const isvalidated = this.$refs.formContract.validate();
			if (isvalidated) {
				this.loading = true;
				const { id } = this.form.documentType;
				const { ok, error } = await this.getTemplate(id);
				if (!ok) {
					let msm = error?.data?.message;
					msm = Array.isArray(msm) ? msm.join(', ') : msm;
					this.showAlert('error', msm);
				}
				this.loading = false;
			}
		},
		showAlert(color = '', msm = '') {
			$EventBus.$emit('showSnack', color || 'success', msm);
		},
	},
	destroyed() {
		this.cleanDocumentType();
		this.cleanTemplate();
	},
	components: {
		SupplierSearch,
		ContractForm,
		NotificationUsersDialog,
	},
};
