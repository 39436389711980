<template>
	<create-agreement />
</template>
<script>
import CreateAgreement from '@/components/agreement/CreateAgreement/CreateAgreement.vue';

export default {
	name: 'CreateContractPage',
	components: { CreateAgreement },
};
</script>
