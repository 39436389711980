<template>
	<v-row dense>
		<v-col cols="12">
			<v-autocomplete
				item-value="id"
				item-text="name"
				placeholder="Búsqueda de cliente/proveedor"
				dense
				outlined
				no-filter
				return-object
				hide-details
				:value="input"
				:items="owners"
				:loading="loading"
				:rules="required ? requiredRule : []"
				@input="(v) => setValue(v)"
				@update:search-input="(val) => listenSearch(val)"
			>
			</v-autocomplete>
		</v-col>
		<v-col cols="12" md="auto">
			<v-btn color="primary" class="text-none" text @click="dialog = true">
				<v-icon left> mdi-account-plus </v-icon>
				Agregar cliente/proveedor
			</v-btn>
			<create-owner-dialog v-model="dialog" />
		</v-col>
	</v-row>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import CreateOwnerDialog from '@/components/contract/create/elements/CreateOwnerDialog/CreateOwnerDialog.vue';

export default {
	name: 'SupplierSearch',
	props: {
		value: {
			type: [Object, Number, String],
			required: false,
		},
		required: {
			type: Boolean,
			required: false,
		},
	},
	data: () => ({
		timeout: null,
		loading: false,
		input: null,
		dialog: false,
		requiredRule: [(v) => !!v || 'Campo requerido'],
	}),
	created() {},
	computed: {
		...mapState('owner', ['owners']),
		owner: {
			get() {
				return this.value || null;
			},
			set(value) {
				this.$emit('input', value);
			},
		},
	},

	watch: {},
	methods: {
		...mapActions('owner', ['listOwners']),
		listenSearch(value) {
			clearTimeout(this.timeout);
			if ((value || '').length > 1 || value == '') {
				this.timeout = setTimeout(async () => {
					this.loading = true;
					await this.listOwners({ q: value });
					this.loading = false;
					clearTimeout(this.timeout);
				}, 800);
			}
		},
		setValue(value) {
			this.$emit('input', value);
		},
	},
	destroyed() {
		clearTimeout(this.timeout);
	},
	components: {
		CreateOwnerDialog,
	},
};
</script>
